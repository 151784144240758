.spinner {
    width: 20vw;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    100% {
      -webkit-transform: rotate3d(0, 1, 0, 360deg);
      -moz-transform: rotate3d(0, 1, 0, 360deg);
      -o-transform: rotate3d(0, 1, 0, 360deg);
      transform: rotate3d(0, 1, 0, 360deg);
    }
  }