.datepicker-container{

}

.datepicker-value{
    
}

.datepicker-options {
    position: absolute;
    z-index: 2;
    background: white;
    box-shadow: 0px 0px 10px -3px #7ac2ff;
    border-radius: 5px;
    transition: opacity 0.2s ease-out, transform 0.2s ease-out;
    will-change: opacity, visibility;
}

.datepicker-options.is-invisible {
    opacity: 0;
    transform: translateY(-10vh);
    visibility: hidden;
}

.datepicker-options.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
}

.datepicker-option{
    padding: 1vh 1vw 1vh 1vw;
    transition: 0.5s;
}

.datepicker-option.selected{
    background: lavender;
}

.datepicker-option:hover{
    background: lavender;
}