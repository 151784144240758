.expenses-container {
    max-height: 87vh;
    overflow: auto;
    padding-top: 1vh;
    padding-left: 2vw;
    padding-right: 2vw;
    margin: 1vh 1vw;
    background: white;
    border-radius: 5px;
}

.expenses-item-container {
    height: 15vh;
    min-height: 120px;
    margin-top: 1vh;
    margin-bottom: 1vh;
    display: flex;
    align-items: center;
    font-size: 6vw;
    gap: 5vw;
    padding-left: 2vw;
    padding-right: 2vw;
    border-radius: 5px;
}

.expenses-item-description {
    width: 33vw;
}

.expense-item-budget-container {
    height: 14vh;
    width: 27vw;
    font-size: 4vw;
    min-height: 111px;
}

.expense-item-budgets {
    background: white;
    border-radius: 5px;
    box-shadow: 0 0 4px 0px black;
    height: 10vh;
    min-height: 80px;
    margin-bottom: 0.5vh;
    display: flex;
    flex-direction: column;
    padding-left: 0.8vw;
    padding-right: 1.5vw;
    text-align: right;
}

.expense-item-budgets-title {
    font-size: 3.5vw;
    color: #8a8a8a;
}

.expenses-list-container {
    height: 78vh;
    border-top: 1px solid lightgray;
    background: #006aff47;
    overflow-y: auto;
}

.expenses-list-total-amount {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 6vw;
    font-weight: bold;
    padding: 0.5vh 0;
}

.expense-edit-dialog-multiply {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 2vw;
    padding-right: 2vw;
}

.expense-item-detail-container {
    border: 1px solid black;
    display: flex;
    align-items: center;
    margin: 1vh 2vw;
    border-radius: 5px;
    padding: 0.5vh 2vw;
    background: #c0c0e2;
    justify-content: space-between;
}

.expense-item-detail-title {
    font-size: 4vw;
    width: 40vw;
}

.expense-item-detail-price {
    font-size: 5vw;
    font-weight: 700;
    padding: 0 2vw;
}

.expense-item-detail-actions {
    display: flex;
}

.expenses-item-icon {
    font-size: 10vw !important;
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
    .expenses-item-container {
        height: 15vh;
        margin-bottom: 2vh;
    }

    .expense-item-budget-container {
        height: 14vh;
        font-size: 2.8vw;
    }

    .expense-item-budgets {
        height: 10vh;
    }

    .expense-item-budgets-title {
        font-size: 2vw;
    }

    .expenses-item-container {
        font-size: 4.5vw;
    }

    .expenses-item-icon {
        font-size: 7vw !important;
    }

    .expense-item-detail-title {
        font-size: 2.5vw;
        width: 48vw;
    }

    .expense-item-detail-price {
        font-size: 4vw;
    }

    .css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root {
        font-size: 3vw;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .expenses-item-container {
        height: 15vh;
        margin-bottom: 2vh;
        min-height: 165px;
    }

    .expense-item-budget-container {
        height: 14vh;
        font-size: 2.8vw;
        min-height: 150px;
    }

    .expense-item-budgets {
        height: 10vh;
        min-height: 110px;
    }

    .expense-item-budgets-title {
        font-size: 2vw;
    }

    .expenses-item-container {
        font-size: 4.5vw;
    }

    .expenses-item-icon {
        font-size: 7vw !important;
    }

    .expense-item-detail-title {
        font-size: 2.5vw;
        width: 48vw;
    }

    .expense-item-detail-price {
        font-size: 4vw;
    }

    .css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root {
        font-size: 3vw;
    }
}

@media only screen and (min-width: 1025px) {
    .expenses-item-container {
        height: 15vh;
        margin-bottom: 2vh;
    }

    .expense-item-budget-container {
        height: 14vh;
        font-size: 2.8vw;
    }

    .expense-item-budgets {
        height: 10vh;
    }

    .expense-item-budgets-title {
        font-size: 2vw;
    }

    .expenses-item-container {
        font-size: 4.5vw;
    }

    .expenses-item-icon {
        font-size: 7vw !important;
    }

    .expense-item-detail-title {
        font-size: 2.5vw;
        width: 48vw;
    }

    .expense-item-detail-price {
        font-size: 4vw;
    }

    .css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root {
        font-size: 3vw;
    }
}