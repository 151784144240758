.stats-active-recurrent-expenses-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    border-bottom: 1px solid lightgray;
    margin-top: 1vh;
}

.stats-active-recurrent-expenses-row:last-child {
    border-bottom: unset;
    margin-bottom: 0.5vh;
}