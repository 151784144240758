.recurrency-periods-container{
    padding: 2vh 3vw;
    height: 80vh;
    overflow-y: auto;
}

.recurrency-period-item {
    background: var(--items-bg);
    height: 7vh;
    display: flex;
    align-items: center;
    font-size: 7vw;
    padding: 0 5vw;
    border-radius: 5px;
    justify-content: space-between;
    margin-bottom: 2vh;
}
.recurrency-period-item .description {
    background: white;
    padding: 0 2vw;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}


@media only screen and (min-width: 481px) and (max-width: 768px) {
    .recurrency-period-item {
        font-size: 4vw;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .recurrency-period-item {
        font-size: 4vw;
    }
}

@media only screen and (min-width: 1025px) {
    .recurrency-period-item {
        font-size: 4vw;
    }
}

