.money-in-container{
    display: flex;
    flex-direction: column;
    height: 90vh;
    overflow-y: auto;
    padding: 1vh 2vw;
}

.money-in-total{
    background: lavender;
    height: 7vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    text-transform: uppercase;
    border-radius: 5px;
}

.money-in-total-items-container{
    background: lavender;
    font-size: 1.5rem;
    text-transform: uppercase;
    border-radius: 5px;
    margin-top: 2.5vh;
    padding: 2vh 1.5vw;
    display: flex;
    flex-direction: column;
    gap : 2vh
}

.money-in-item {
    background-color: #4eff1d6b;
    display: flex;
    justify-content: space-around;
    padding: 1vh 0.5vw;
    border-radius: 5px;
    box-shadow: 0 0 4px 2px #03825db5;
}