body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: #4169e1bf;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100000;
    background-color: #77889975;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.loading-overlay.show{
  visibility: visible;
}

.loading-overlay.hide{
  visibility: hidden;
}

.shadows {
  box-shadow: 0 0 6px 1px #000e6e;
}