.summary-container {
    padding: 1vh 2vw;
    max-height: 87vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 3vh;
}

.summary-money-in-total-container {
    background: white;
    padding: 0 2vw;
    box-shadow: 0px 3px 2px 4px #3f019c;
}

.summary-money-in-total-title {
    font-size: 6vw;
    text-decoration: underline;
    text-underline-offset: 5px;
}

.summary-money-in-total-amount {
    font-size: 10vw;
    text-align: right;
    font-weight: 700;
}

.summary-money-closing-container {
    font-size: 10vw;
    display: flex;
    justify-content: space-between;
    font-weight: 700;
}

.summary-money-closing-grow {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 5vw;
}

.summary-month-closing-descriptions {
    text-align: left;
    font-size: 6.8vw;
}

.stats-details-critical-categories-item {
    display: flex;
    align-items: center;
}

.stats-details-critical-categories-item-info-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1vh;
}

.stats-details-critical-categories-item-info-title {
    font-size: small;
}

.stats-details-critical-categories-item-info-content {
    font-weight: bold;
    font-size: medium;
}

.stats-details-critical-categories-item-info-content.number {
    text-align: right;
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
    .summary-money-in-total-title {
        font-size: 4vw;
    }

    .summary-money-closing-grow{
        font-size:  3vw;
    }

    .summary-money-closing-container,
    .summary-money-in-total-amount {
        font-size: 5vw;
    }

    .summary-money-in-out-linear-progress {
        font-size: 3vw !important;
    }

    .summary-month-closing-descriptions {
        font-size: 4.8vw;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .summary-money-in-total-title {
        font-size: 4vw;
    }

    .summary-money-closing-grow{
        font-size:  3vw;
    }

    .summary-money-closing-container,
    .summary-money-in-total-amount {
        font-size: 5vw;
    }

    .summary-money-in-out-linear-progress {
        font-size: 3vw !important;
    }

    .summary-month-closing-descriptions {
        font-size: 4.8vw;
    }
}

@media only screen and (min-width: 1025px) {
    .summary-money-in-total-title {
        font-size: 4vw;
    }

    .summary-money-closing-grow{
        font-size:  3vw;
    }

    .summary-money-closing-container,
    .summary-money-in-total-amount {
        font-size: 5vw;
    }

    .summary-money-in-out-linear-progress {
        font-size: 3vw !important;
    }

    .summary-month-closing-descriptions {
        font-size: 4.8vw;
    }
}