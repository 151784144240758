.profile-container {
    height: 78.5vh;
    padding: 3vh 3vw;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
}

.profile-tile {
    /* background: white; */
    padding: 1vh 3vw !important;
    display: flex;
    flex-direction: column;
    gap: 5.5vh;
    justify-content: space-between;
    border-radius: 5px;
/*     box-shadow: 0 0 6px 0px black; */
    width: 85vw;
}

.profile-data-section {
    display: flex;
    flex-direction: column;
    gap: 2vh;
}

.profile-buttons-section {
    display: flex;
    flex-direction: column;
    gap: 1vh;
}

.profile-name-title {

}

.profile-name-content {
    font-size: 9vw;
}

.profile-email-content {
    font-size: 7vw;
    word-break: break-all;
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
    .profile-name-content {
        font-size: 5vw;
    }
    .profile-name-title {
        font-size: 3vw;
    }

    .profile-email-content{
        font-size: 5vw;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .profile-name-content {
        font-size: 5vw;
    }
    .profile-name-title {
        font-size: 3vw;
    }

    .profile-email-content{
        font-size: 5vw;
    }
}

@media only screen and (min-width: 1025px) {
    .profile-name-content {
        font-size: 5vw;
    }
    .profile-name-title {
        font-size: 3vw;
    }

    .profile-email-content{
        font-size: 5vw;
    }
}