.header-container {
    display: flex;
    min-height: 80px;
    max-height: 95px;
    height: 10vh;
    justify-content: space-between;
    align-items: center;
    font-size: 5vw;
    /* background: white; */
    background: gold;
    box-shadow: 0px 6px 5px -5px #7ac2ff;
    padding: 0 2vw 0 2vw;
}

.header-date-picker {
    display: flex;
    gap: 5vw;
}

.header-hamburger-menu{

}

@media only screen and (min-width: 481px) and (max-width: 768px) {
    .header-container {
        font-size: 4vw;
        min-height: 90px;
        max-height: 105px;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .header-container {
        font-size: 4vw;
        min-height: 105px;
        max-height: 120px;
    }
}

@media only screen and (min-width: 1025px) {
    .header-container {
        font-size: 4vw;
        min-height: 120px;
    }
}