:root {
  --items-bg: #c0c0e2;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.help-content-images {
  width: 90%;   
}

.help-content-images-half {
  width: 45%;
}

.icon-size {
  font-size: 6.5vw !important;
}

.button{
  min-height: 27px;
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .icon-size {
    font-size: 4vw !important;
  }

  .help-dialog {
    font-size: larger;
  }

  .button {
    font-size: x-large !important;
  }

  .help-dialog-title {
    font-size: larger !important;
  }

  .text-field-fonts {
    font-size: x-large !important;
  }

  .header-hamburger-menu {
    font-size: 7vw !important;
  }

  .help-content-images {
    width: 55%;
  }

  .help-content-images-half {
    width: 50%;
  }

  /*dimensione testo dentro input*/
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
  /*placeholder quando vuoto*/
  .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    font-size: 3.5vw !important;
  }

  /*floating label quando è pieno*/
  .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
    font-size: 3.5vw !important;
    transform: translate(14px, -15px) scale(0.75) !important;
  }

  /*grandezza "buco" dove si inserisce il floating label*/
  .css-14lo706{
    font-size: 2.8vw !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .icon-size {
    font-size: 4vw !important;
  }

  .help-dialog {
    font-size: larger;
  }

  .button {
    font-size: x-large !important;
  }

  .help-dialog-title {
    font-size: larger !important;
  }

  .text-field-fonts {
    font-size: x-large !important;
  }

  .header-hamburger-menu {
    font-size: 7vw !important;
  }

  .help-content-images {
    width: 55%;
  }

  .help-content-images-half {
    width: 50%;
  }

  /*dimensione testo dentro input*/
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
  /*placeholder quando vuoto*/
  .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    font-size: 3.5vw !important;
  }

  /*floating label quando è pieno*/
  .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
    font-size: 3.5vw !important;
    transform: translate(14px, -15px) scale(0.75) !important;
  }

  /*grandezza "buco" dove si inserisce il floating label*/
  .css-14lo706{
    font-size: 2.8vw !important;
  }
}

@media only screen and (min-width: 1025px){
  .icon-size {
    font-size: 4vw !important;
  }

  .help-dialog {
    font-size: larger;
  }

  .button {
    font-size: x-large !important;
  }

  .help-dialog-title {
    font-size: larger !important;
  }

  .text-field-fonts {
    font-size: x-large !important;
  }

  .header-hamburger-menu {
    font-size: 7vw !important;
  }

  .help-content-images {
    width: 55%;
  }

  .help-content-images-half {
    width: 50%;
  }

  /*dimensione testo dentro input*/
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
  /*placeholder quando vuoto*/
  .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    font-size: 3.5vw !important;
  }

  /*floating label quando è pieno*/
  .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
    font-size: 3.5vw !important;
    transform: translate(14px, -15px) scale(0.75) !important;
  }

  /*grandezza "buco" dove si inserisce il floating label*/
  .css-14lo706{
    font-size: 2.8vw !important;
  }
}