.categories-container {
    /* display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 5vw; */
    padding-top: 1vh;
    max-height: 82vh;
    overflow-y: auto;
    padding-bottom: 1vh;
}

.categories-empty-container {
    height: 82vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;  
    padding: 0 3vw; 
}

.category-item {
    width: 80vw;
    aspect-ratio: 2 / 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    gap: 2vh;
    border-radius: 10px;
    padding-left: 1vw;
    padding-right: 1vw;
    box-shadow: 0 0 6px 0px black;
    font-weight: 700;
    text-align: center;
    position: relative;
    margin: auto;
    margin-bottom: 3vh;
}

.category-item-budget-badge {
    position: absolute;
    top: 0;
    right: 4vw;
}

.icon-selector-container,
.color-selector-container {
    border: 3px solid black;
    display: flex;
    flex-wrap: wrap;
    padding: 0.5vh 1vw;
}

.icon-selector-item {
    width: 11.5vw;
    aspect-ratio: 1 / 1;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background 0.5s;
}

.icon-selector-item.selected {
    background: lavender;
}

.color-selector-container {
    gap: 3vw
}

.color-selector-item {
    width: 11.5vw;
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.category-item-reorder {
    font-size: 7vw;
    margin-bottom: 1vh;
    background: lightgray;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
}

.settings-category-item-icon{
    font-size: 5rem !important;
}

.categories-loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 84vh;
    font-size: 10vw !important;
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
    .categories-container {
        display: flex;
        flex-wrap: wrap;
    }

    .category-item {
        width: 25vw;
        padding-bottom: 1vh;
    }

    .category-item-budget-badge {
        right: 1vw;
    }

    .settings-category-item-icon {
        margin-top: 2vh;
        font-size: 4.2rem !important;
    }

    .category-item-reorder {
        font-size: 4.5vw;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .categories-container {
        display: flex;
        flex-wrap: wrap;
    }

    .category-item {
        width: 25vw;
        padding-bottom: 1vh;
    }

    .category-item-budget-badge {
        right: 1vw;
    }

    .settings-category-item-icon {
        margin-top: 2vh;
        font-size: 4.2rem !important;
    }

    .category-item-reorder {
        font-size: 4.5vw;
    }
}

@media only screen and (min-width: 1025px) {
    .categories-container {
        display: flex;
        flex-wrap: wrap;
    }

    .category-item {
        width: 25vw;
        padding-bottom: 1vh;
    }

    .category-item-budget-badge {
        right: 1vw;
    }

    .settings-category-item-icon {
        margin-top: 2vh;
        font-size: 4.2rem !important;
    }

    .category-item-reorder {
        font-size: 4.5vw;
    }
}