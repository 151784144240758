.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 100vw; */
    padding: 0 4vw;
    height: 100vh;
    flex-direction: column;
}

.login-body {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 70vh;
    min-height: 550px;
}

.login-title{
    /* position: fixed;
    top: 3vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-form{
    /* height: 30vh; */
    height: 250px;
    width: 70vw;
    background-color: gold;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 5vh 5vw;
}

.registration-form {
    height: 50vh;
    margin-bottom: 2vh;
}

.login-form-back-container{
    font-size: 5vw !important;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5vw;
    position: fixed;
    top: 5vh;
    box-shadow: 0 0 6px 0px black;
    border-radius: 5px;
    padding: 1vh 17vw;
    background: #3655e161;
}

.login-button{
  width: 100%;  
  background-color: white;
  display: flex;
  justify-content: center;
  font-size: 1.1rem;
  padding: 1vh 0.5vw;
}
