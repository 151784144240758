.header{
    display: flex;
    background-color: gold;
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
    gap : 2vw
}

.month-selector{
    margin-left: 10vw;
}

.month-selector-container {
    position: absolute;
    background: white;
    text-align: center;
    visibility: hidden;
}

.month-selector-item{
    padding: 1vh 1vw;
}

.dialog-title-with-button {
    display : flex;
    justify-content : space-between; 
    align-items : center; 
    border-bottom : 1px solid lightgray
}

.dialog-title {
    border-bottom : 1px solid lightgray
}

.simulator-probable-saving {
    min-height: 195px;
}

.simulator-wanted-saving {
    min-height: 275px;
}