.drawer-item{
   font-size: 2rem; 
   padding: 1.5vw 2vh;
}

.drawer-item:hover{
    background-color: #4169e127;
}

.drawer-item.active{
    background-color: #4169e127;
}